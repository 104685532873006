import { Box, Stack } from '@mui/material';
import { lemmatizeWordAccordingToNumber } from 'modules/lemmatize/lemmatizeWordAccordingToNumber';
import { Text } from 'modules/theme/components/text/Text';
import React, { FC } from 'react';

interface IOfferCount {
    count: number;
}

export const OfferCount: FC<IOfferCount> = ({ count }) => {

    const title = lemmatizeWordAccordingToNumber(count, {
        eqZero: 'vyhovujúcich ponúk',
        eqOne: 'vyhovujúca ponuka',
        betweenTwoAndFourInclusive: 'vyhovujúce ponuky',
        moreThenFour: 'vyhovujúcich ponúk',
        withoutNumber: true,
    });

    return (
        <Stack direction='row'
            p={1.5}
            borderRadius='8px'
            bgcolor='rgba(0, 0, 0, 0.64)'
            boxShadow='0px 8px 24px 0px rgba(0, 0, 0, 0.16)'
            alignItems='center'
            justifyContent='center'
            flexWrap='wrap'
            sx={{
                opacity: .9,
                backdropFilter: 'blur(16px)',
            }}
        >
            <Box px={1}>
                <Text inheritColor variant='h2' noWrap>
                    {count}
                </Text>
            </Box>
            <Box px={1} textAlign='center'>
                <Text inheritColor variant='label2'>
                    {title}
                </Text>
            </Box>
        </Stack>
    );
};
