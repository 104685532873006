import { IAdvertisementItem } from 'modules/gtmEvents/interfaces/view/advertisement/partials/IAdvertisementItem';
import {
    getGtmLocationStringHelper,
    getItemVariant,
    getOrderIndex,
} from 'modules/gtmEvents/helpers/gtmHelper';
import { ECategory } from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/category/ECategory';
import { ESubcategory } from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/category/ESubcategory';
import { EItemBrand } from 'modules/gtmEvents/enums/EItemBrand';
import { EItemListId } from 'modules/gtmEvents/enums/EItemListId';

import {
    IAdvertisementGtmListingItem
} from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/IAdvertisementGtmListingItem';
import { ISearchAgentData } from 'components/searchAgent/interfaces/form/data/ISearchAgentData';
import { ISubscribeAlert } from 'modules/gtmEvents/interfaces/subscribeAlert/ISubscribeAlert';
import { EGtmEvent } from 'modules/gtmEvents/enums/EGtmEvent';
import { EFormState } from 'modules/gtmEvents/enums/EFormState';
import { EMethod } from 'modules/gtmEvents/enums/EMethod';
import { LoginPositionRecords } from 'modules/gtmEvents/records/ESearchAgentFrequencyToEAlertFrequencyRecord';

export const getAdvertisementListItem = (
    item: IAdvertisementGtmListingItem,
    index: number,
    itemIdPrefix: string,
): IAdvertisementItem => {

    const {
        photoUrl,
        category,
        transaction,
        realEstateState,
        price: advPrice,
        location,
        advertiser,
    } = item;

    const affiliationPrepare = [];
    if (advertiser?.agency?.name) {
        affiliationPrepare.push(item.advertiser?.agency?.name);
    }
    if (advertiser?.name) {
        affiliationPrepare.push(advertiser?.name);
    }

    const locationName = getGtmLocationStringHelper(
        location?.country,
        location?.county,
        location?.district,
        location?.city,
    );

    let price = '';
    if (advPrice?.priceValue) {
        price = advPrice.priceValue;
    }

    let item_category = 'undefined';
    if (category?.mainValue && transaction) {
        item_category = `${
            ECategory[category.mainValue]
        }, ${transaction.toLowerCase()}`;
    }

    let item_category2 = 'undefined';
    if (category?.subValue && transaction) {
        item_category2 = `${
            ESubcategory[category.subValue]
        }, ${transaction.toLowerCase()}`;
    }

    return {
        item_id: `${itemIdPrefix}_${item.id}`,
        item_name: item.title,
        affiliation: affiliationPrepare.join(', '),
        index,
        item_brand: EItemBrand.CLASSIFIED_LISTING,
        item_category,
        item_category2,
        item_category3: locationName || 'undefined',
        item_category4: transaction || 'undefined',
        item_category5: realEstateState || 'undefined',
        item_list_id: EItemListId.CLASSIFIED_SEARCH_RESULTS,
        item_list_name: item.gaItemListName as EItemListId,
        item_variant: getItemVariant(photoUrl, false),
        location_id: locationName || 'undefined',
        price,
        quantity: 1,
    };
};

export const getAdvertisementListItems = (
    items: IAdvertisementGtmListingItem[],
    currentPage: number,
    limit: number,
    itemIdPrefix: string,
): IAdvertisementItem[] => {
    return items.map((item, index) =>
        getAdvertisementListItem(
            item,
            getOrderIndex(index, currentPage, limit),
            itemIdPrefix,
        )
    );
};

export const getSubscribeAlertEvent = (searchAgentData: ISearchAgentData, success: boolean): ISubscribeAlert => {
    return {
        event: EGtmEvent.SUBSCRIBE_ALERT,
        form_state: success ? EFormState.SUCCESS : EFormState.ERROR,
        method: EMethod.ALERT_SUBSCRIPTION_FORM,
        alert_channel: 'email',
        alert_frequency: LoginPositionRecords[searchAgentData.frequency],
    };
};
