import axios, { AxiosResponse } from 'axios';
import { searchAgentApi } from 'modules/api/client';
import { isValueInEnum } from 'modules/enums/checker/isValueInEnum';
import { AdvertisementSearchAgentSaveSearchAgentDto } from 'modules/api/generated';
import { ESearchAgentErrorStatusCode } from 'components/searchAgent/enums/error/messages/ESearchAgentErrorStatusCode';

export class SearchAgent {
    public async createSearchAgent(
        filter: AdvertisementSearchAgentSaveSearchAgentDto
    ): Promise<void> {
        let response: AxiosResponse<void>;

        try {
            response = await searchAgentApi.saveSearchAgent(filter);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (isValueInEnum(error.response?.status.toString(), ESearchAgentErrorStatusCode)) {
                    throw new Error(ESearchAgentErrorStatusCode.MAX_SEARCH_AGENT_REACHED);
                }
            }
            throw new Error(
                `Failed to post searchAgentApi. Error: ${error.message}`
            );
        }

        if (response.status !== 201) {
            throw new Error('Post searchAgentApi failed');
        }
    }

    public async authenticateSearchAgent(
        reporterId: number,
    ): Promise<void> {
        let response: AxiosResponse<void>;

        try {
            response = await searchAgentApi.authenticateSearchAgent(reporterId);
        } catch (error) {
            throw new Error(
                `Failed to authenticate search agent. Error: ${error.message}`
            );
        }

        if (response.status !== 201) {
            throw new Error('Search agent authentication failed');
        }
    }

    public async searchAgentIsAuthenticated(
        reporterId: number,
    ): Promise<boolean> {
        let response: AxiosResponse<boolean>;

        try {
            response = await searchAgentApi.searchAgentIsAuthenticated(reporterId);
        } catch (error) {
            throw new Error(
                `Failed to get search agent is authenticated. Error: ${error.message}`
            );
        }

        if (response.status !== 200) {
            throw new Error('Get search agent is authenticated failed');
        }

        return response.data;
    }
}
