import { useCallback } from 'react';
import { Text } from 'modules/theme/components/text/Text';
import useNotification from 'modules/notification/useNotification';
import { useLocationStore } from 'components/advertisementsFilter/modules/location/store/useLocationStore';
import { useCategoriesStore } from 'components/advertisementsFilter/modules/categories/store/useCategoriesStore';
import { useTransactionStore } from 'components/advertisementsFilter/modules/transaction/store/useTransactionStore';

interface IReturn {
    validate: () => boolean;
}

const useValidateSearchAgent = (): IReturn => {
    const selectedLocations = useLocationStore(
        (store) => store.selectedLocations
    );
    const selectedCategories = useCategoriesStore(
        (store) => store.selectedCategories
    );
    const selectedTransaction = useTransactionStore(
        (store) => store.selectedTransaction
    );

    const { error: showErrorFlashMessage } = useNotification();

    const handleValidation = useCallback(() => {
        if (
            !selectedLocations?.length ||
            !selectedCategories?.length ||
            !selectedTransaction
        ) {
            const missingFields = [];
            if (!selectedLocations?.length) {
                missingFields.push('Lokalita');
            }
            if (!selectedTransaction) {
                missingFields.push('Typ ponuky');
            }
            if (!selectedCategories?.length) {
                missingFields.push('Typ nehnuteľnosti');
            }
            showErrorFlashMessage(
                <>
                    <Text variant='label1' semibold>
                        Pre uloženie hľadania musíte vybrať ešte:
                    </Text>
                    <Text
                        variant='label1'
                        semibold
                        color={(palette) => palette.primary}
                    >
                        {missingFields.join(', ')}
                    </Text>
                </>
            );

            return false;
        }

        return true;
    }, [
        selectedLocations,
        selectedCategories,
        selectedTransaction,
        showErrorFlashMessage,
    ]);

    return {
        validate: handleValidation,
    };
};

export default useValidateSearchAgent;
