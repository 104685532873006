import { FC } from 'react';
import Image from 'components/image/Image';
import Icon from 'modules/theme/components/assets/badges/svg/DevProject.svg?url';

interface IDevProject {
    width: number | string;
}

export const DevProject: FC<IDevProject> = ({ width }) => {
    const w = parseInt(`${width}`);

    return <Image alt='' width={w} height={w} src={Icon} />;
};
