import { FC } from 'react';
import { Stack } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { Mobile } from 'modules/theme/components/responsive';

interface IPrice {
    price: string;
    unitPrice?: string;
}

export const Price: FC<IPrice> = ({ price, unitPrice }) => {

    return (
        <Stack
            gap={{ xs: 1, md: .5 }}
            direction={{ xs: 'row', md: 'column' }}
            alignItems={{ xs: 'baseline', md: 'flex-end' }}
        >
            <Text mobileVariant='h6' variant='h4' inheritColor noWrap>
                {price}
            </Text>
            {unitPrice && <Text variant='label2' inheritColor noWrap>
                <Mobile>&nbsp;/&nbsp;</Mobile>
                {unitPrice}
            </Text>}
        </Stack>
    );
};
