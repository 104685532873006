import useDialog from 'modules/dialog/useDialog';
import { Theme, useMediaQuery } from '@mui/material';
import { ReactNode, useCallback, useEffect } from 'react';
import useSession from 'modules/stores/session/useSession';
import { useLoginStore } from 'modules/stores/login/useLoginStore';
import { useLoginModal } from 'components/loginModal/hooks/useLoginModal';
import { ELoginPosition } from 'modules/gtmEvents/enums/login/ELoginPosition';
import { usePostSearchAgent } from 'components/searchAgent/hooks/usePostSearchAgent';
import { useLocalStorageItem } from 'modules/localStorage/hooks/useLocalStorageitem';
import { useSearchAgentTitle } from 'components/searchAgent/hooks/useSearchAgentTitle';
import useValidateSearchAgent from 'components/searchAgent/hooks/useValidateSearchAgent';
import { SaveSearchModal } from 'components/searchAgent/components/saveSearchModal/SaveSearchModal';

interface IReturn {
    show: () => void;
    modal: JSX.Element | ReactNode | null;
}

const useSearchAgent = (): IReturn => {
    const { session } = useSession();
    const { loginModal, showLoginDialog } = useLoginModal();
    const { getSearchTitle, searchTitle } = useSearchAgentTitle();

    const { onSend, isLoading } = usePostSearchAgent();
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );
    const setLoginPosition = useLoginStore((store) => store.setLoginPosition);

    const {
        set: setSearchAgentShowLSItem,
        get: getSearchAgentShowLSItem,
        remove: removeSearchAgentShowLSItem,
    } = useLocalStorageItem('isSearchAgentClicked');

    const { validate } = useValidateSearchAgent();

    const isLoggedIn = !!session;

    const {
        component: modal,
        show: showDialog,
        hide: hideDialog,
    } = useDialog({
        content: (
            <SaveSearchModal
                onClose={() => hideDialog()}
                searchName={searchTitle}
                isLoading={isLoading}
                onSend={onSend}
                email={session?.email || ''}
            />
        ),
        paperProps: {
            sx: {
                borderRadius: (theme) =>
                    isMobile ? theme.spacing(2, 2, 0, 0) : theme.spacing(2),
            },
        },
        dialogContentProps: {
            sx: {
                padding: isMobile ? 3 : 4,
            },
        },
        wrapContentIntoBox: false,
        fullScreen: isMobile,
        fullWidth: isMobile,
        heightFitContent: isMobile,
        positionBottom: isMobile,
    });

    const handleShowDialog = useCallback(() => {
        if (!validate()) {
            return;
        }

        getSearchTitle();
    }, [validate, getSearchTitle]);

    useEffect(() => {
        if (!searchTitle) {
            return;
        }

        showDialog();
    }, [searchTitle, showDialog]);

    useEffect(() => {
        if (getSearchAgentShowLSItem()) {
            handleShowDialog();
            removeSearchAgentShowLSItem();
        }
    }, [
        handleShowDialog,
        getSearchAgentShowLSItem,
        removeSearchAgentShowLSItem,
    ]);

    const onShowLoginDialog = useCallback(() => {
        setSearchAgentShowLSItem('true');
        setLoginPosition(ELoginPosition.SEARCH_AGENT);
        showLoginDialog();
    }, [setLoginPosition, showLoginDialog, setSearchAgentShowLSItem]);

    const onShowDialog = useCallback(() => {
        handleShowDialog();
    }, [handleShowDialog]);

    return {
        show: isLoggedIn ? onShowDialog : onShowLoginDialog,
        modal: isLoggedIn ? modal : loginModal,
    };
};

export default useSearchAgent;
