import { useCallback, useMemo } from 'react';
import useLoading from 'modules/stores/loading/useLoading';
import useAnalytics from 'modules/stores/analytics/useAnalytics';
import useNotification from 'modules/notification/useNotification';
import { isValueInEnum } from 'modules/enums/checker/isValueInEnum';
import SavedIcon from 'modules/theme/components/icons/module/Saved.svg';
import { SearchAgent } from 'modules/api/clients/searchAgent/SearchAgent';
import useValidateSearchAgent from 'components/searchAgent/hooks/useValidateSearchAgent';
import { IExtendedFilter } from 'components/searchAgent/interfaces/filter/IExtendedFilter';
import { ISearchAgentData } from 'components/searchAgent/interfaces/form/data/ISearchAgentData';
import { useExtendedStore } from 'components/advertisementsFilter/modules/extended/store/useExtendedStore';
import { useLocationStore } from 'components/advertisementsFilter/modules/location/store/useLocationStore';
import { getSearchAgentRequestoDto } from 'components/searchAgent/helpers/api/mapper/getSearchAgentRequestoDto';
import { useCategoriesStore } from 'components/advertisementsFilter/modules/categories/store/useCategoriesStore';
import { useTransactionStore } from 'components/advertisementsFilter/modules/transaction/store/useTransactionStore';
import { ESearchAgentErrorStatusCode } from 'components/searchAgent/enums/error/messages/ESearchAgentErrorStatusCode';
import { validateSearchAgentRequestDto } from 'components/searchAgent/helpers/api/validation/validateSearchAgentRequestDto';
import { getSubscribeAlertEvent } from 'modules/gtmEvents/helpers/events/advertisement/listing/partials/advertisementListDataHelper';
import { SearchAgentErrorStatusCodeToMessagesRecord } from 'components/searchAgent/constants/record/error/SearchAgentErrorStatusCodeToMessagesRecord';

interface IReturn {
    isLoading: boolean;
    onSend: (data: ISearchAgentData) => Promise<boolean>;
}

export const usePostSearchAgent = (): IReturn => {
    const { start: startLoading, stop: stopLoading, isLoading } = useLoading();
    const {
        gtm: { sendEvent: gtmSendEvent },
    } = useAnalytics();
    const { error: showErrorFlashMessage, basic: showBasicFlashMessage } =
        useNotification();

    const locations = useLocationStore((store) => store.selectedLocations);
    const categories = useCategoriesStore((store) => store.selectedCategories);
    const transaction = useTransactionStore(
        (store) => store.selectedTransaction
    );

    const {
        fulltext,
        isActive,
        isOnlyWithVideo,
        isOnlyNewBuilding,
        prices,
        area,
    } = useExtendedStore();
    const extendedFilter = useMemo<IExtendedFilter>(
        () => ({
            fulltext,
            isActive,
            isOnlyWithVideo,
            isOnlyNewBuilding,
            prices,
            area,
        }),
        [fulltext, isActive, isOnlyWithVideo, isOnlyNewBuilding, prices, area]
    );

    const { validate } = useValidateSearchAgent();

    const onSend = useCallback(
        async (formData: ISearchAgentData): Promise<boolean> => {
            if (!validate()) {
                return;
            }

            const requestoDtoFilter = getSearchAgentRequestoDto(
                formData,
                locations,
                categories,
                transaction,
                extendedFilter
            );

            const isValid = validateSearchAgentRequestDto(requestoDtoFilter);

            if (!isValid) {
                return;
            }

            try {
                startLoading();
                await new SearchAgent().createSearchAgent(requestoDtoFilter);
                stopLoading();
                gtmSendEvent(getSubscribeAlertEvent(formData, true));
                showBasicFlashMessage('Hľadanie je uložené', SavedIcon);
                return true;
            } catch (e) {
                gtmSendEvent(getSubscribeAlertEvent(formData, false));
                if (
                    isValueInEnum(
                        e.message,
                        ESearchAgentErrorStatusCode
                    )
                ) {
                    showErrorFlashMessage(
                        SearchAgentErrorStatusCodeToMessagesRecord[
                            e.message
                        ]
                    );
                } else {
                    showErrorFlashMessage('Hľadanie sa nepodarilo uložiť');
                }
                return false;
            } finally {
                stopLoading();
            }
        },
        [
            validate,
            locations,
            categories,
            transaction,
            stopLoading,
            gtmSendEvent,
            startLoading,
            extendedFilter,
            showBasicFlashMessage,
            showErrorFlashMessage,
        ]
    );

    return {
        isLoading,
        onSend,
    };
};
