import React, { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';
import {
    LinkToGdpr
} from 'components/page/advertisement/detail/components/common/advertiser/common/contactModal/common/content/partials/common/checkboxDisclaimer/partials/linkToGdpr/LinkToGdpr';

export const GdprDisclaimer: FC = () => {
    return (
        <Text variant='label2' color={(palette) => palette.labelSecondary}>
            Vaše údaje zadané do kontaktného formulára budú odoslané spoločnosti United Classifieds,
            s.r.o. Bližšie informácie k ochrane osobných údajov nájdete  <LinkToGdpr />.
        </Text>
    );
};