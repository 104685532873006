import { FC } from 'react';
import { Theme, IconButton, Box } from '@mui/material';
import { Button } from 'modules/theme/components/button/Button';
import { Svg } from 'modules/theme/components/svg/Svg';
import DogOutlineIcon from 'modules/theme/components/icons/basic/DogOutline.svg';
import useSearchAgent from 'components/searchAgent/hooks/useSearchAgent';

interface ISaveSearchBtn {
    isMobile?: boolean;
}

export const SaveSearchBtn: FC<ISaveSearchBtn> = ({ isMobile }) => {
    const { modal, show } = useSearchAgent();

    return (
        <Box
            minWidth={{ xs: 0, md: 180 }}
            sx={{
                '& .MuiButton-root': {
                    padding: '11px 16px',
                },
            }}
        >
            {isMobile ? (
                <IconButton
                    onClick={show}
                    aria-label='save search button'
                    sx={{
                        width: 40,
                        height: 40,
                        background: 'transparent',
                        border: (theme: Theme) =>
                            `${theme.spacing(
                                0.125
                            )} solid rgba(182, 182, 182, 0.36)`,
                        color: 'inherit',
                        '&:hover': {
                            borderColor: 'inherit',
                        },
                    }}
                >
                    <Svg icon component={DogOutlineIcon} width={24}/>
                </IconButton>
            ) : (
                <Button
                    tertiary
                    onClick={show}
                    text='Uložiť hľadanie'
                    startIcon={DogOutlineIcon}
                    ariaLabel='save search button'
                />
            )}
            {modal}
        </Box>
    );
};
