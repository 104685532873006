import {
    SearchAgentFilterParametersDtoTransactionEnum,
} from 'modules/api/generated';
import { ETransaction } from 'modules/api/clients/advertisement/common/enums/parameters/transaction/ETransaction';

// export const TransactionEnumRecord: Record<
//     ETransaction,
//     AdvertisementsCountFilterParametersDtoTransactionEnum
// > = {
//     [ETransaction.PURCHASE]:
//         AdvertisementsCountFilterParametersDtoTransactionEnum.Purchase,
//     [ETransaction.HIREOUT]:
//         AdvertisementsCountFilterParametersDtoTransactionEnum.Hireout,
//     [ETransaction.RENT]:
//         AdvertisementsCountFilterParametersDtoTransactionEnum.Rent,
//     [ETransaction.EXCHANGE]:
//         AdvertisementsCountFilterParametersDtoTransactionEnum.Exchange,
//     [ETransaction.AUCTION]:
//         AdvertisementsCountFilterParametersDtoTransactionEnum.Auction,
//     [ETransaction.SALE]:
//         AdvertisementsCountFilterParametersDtoTransactionEnum.Sale,
// };

export const TransactionEnumRecord: Record<
    ETransaction,
    SearchAgentFilterParametersDtoTransactionEnum
> = {
    [ETransaction.PURCHASE]:
        SearchAgentFilterParametersDtoTransactionEnum.Purchase,
    [ETransaction.HIREOUT]:
        SearchAgentFilterParametersDtoTransactionEnum.Hireout,
    [ETransaction.RENT]: SearchAgentFilterParametersDtoTransactionEnum.Rent,
    [ETransaction.EXCHANGE]:
        SearchAgentFilterParametersDtoTransactionEnum.Exchange,
    [ETransaction.AUCTION]:
        SearchAgentFilterParametersDtoTransactionEnum.Auction,
    [ETransaction.SALE]: SearchAgentFilterParametersDtoTransactionEnum.Sale,
};
