import { SearchAgentFilterParametersDtoCategoriesEnum } from 'modules/api/generated';
import { ESubCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';
import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';

export const CategoryEnumRecord: Record<
    EMainCategory | ESubCategory,
    SearchAgentFilterParametersDtoCategoriesEnum
> = {
    [EMainCategory.REAL_ESTATES]:
        SearchAgentFilterParametersDtoCategoriesEnum.RealEstates,
    [EMainCategory.APARTMENTS]:
        SearchAgentFilterParametersDtoCategoriesEnum.Apartments,
    [EMainCategory.HOUSES]:
        SearchAgentFilterParametersDtoCategoriesEnum.Houses,
    [EMainCategory.SECONDARY_HOUSES]:
        SearchAgentFilterParametersDtoCategoriesEnum.Houses,
    [EMainCategory.COTTAGES_AND_CABINS]:
        SearchAgentFilterParametersDtoCategoriesEnum.CottagesAndCabins,
    [EMainCategory.LANDS]:
        SearchAgentFilterParametersDtoCategoriesEnum.Lands,
    [EMainCategory.SECONDARY_RECREATIONAL_PROPERTIES]:
        SearchAgentFilterParametersDtoCategoriesEnum.SecondaryRecreationalProperties,
    [EMainCategory.SECONDARY_LANDS]:
        SearchAgentFilterParametersDtoCategoriesEnum.Lands,
    [EMainCategory.SECONDARY_SPACES_AND_OBJECTS]:
        SearchAgentFilterParametersDtoCategoriesEnum.SecondarySpacesAndObjects,
    [EMainCategory.SPACES]:
        SearchAgentFilterParametersDtoCategoriesEnum.Spaces,
    [EMainCategory.OBJECTS]:
        SearchAgentFilterParametersDtoCategoriesEnum.Objects,
    [ESubCategory.STUDIO_APARTMENT]:
        SearchAgentFilterParametersDtoCategoriesEnum.StudioApartment,
    [ESubCategory.DOUBLE_STUDIO_APARTMENT]:
        SearchAgentFilterParametersDtoCategoriesEnum.DoubleStudioApartment,
    [ESubCategory.ONE_ROOM_APARTMENT]:
        SearchAgentFilterParametersDtoCategoriesEnum.OneRoomApartment,
    [ESubCategory.TWO_ROOM_APARTMENT]:
        SearchAgentFilterParametersDtoCategoriesEnum.TwoRoomApartment,
    [ESubCategory.THREE_ROOM_APARTMENT]:
        SearchAgentFilterParametersDtoCategoriesEnum.ThreeRoomApartment,
    [ESubCategory.FOUR_ROOM_APARTMENT]:
        SearchAgentFilterParametersDtoCategoriesEnum.FourRoomApartment,
    [ESubCategory.FIVE_PLUS_ROOM_APARTMENT]:
        SearchAgentFilterParametersDtoCategoriesEnum.FivePlusRoomApartment,
    [ESubCategory.HOLIDAY_APARTMENT]:
        SearchAgentFilterParametersDtoCategoriesEnum.HolidayApartment,
    [ESubCategory.LOFT]:
        SearchAgentFilterParametersDtoCategoriesEnum.Loft,
    [ESubCategory.MAISONETTE]:
        SearchAgentFilterParametersDtoCategoriesEnum.Maisonette,
    [ESubCategory.OTHER_TYPE_OF_APARTMENT]:
        SearchAgentFilterParametersDtoCategoriesEnum.OtherTypeOfApartment,
    [ESubCategory.FAMILY_HOUSE]:
        SearchAgentFilterParametersDtoCategoriesEnum.FamilyHouse,
    [ESubCategory.COUNTRY_HOUSE]:
        SearchAgentFilterParametersDtoCategoriesEnum.CountryHouse,
    [ESubCategory.FARM_SETTLEMENT]:
        SearchAgentFilterParametersDtoCategoriesEnum.FarmSettlement,
    [ESubCategory.MOBILE_HOUSE]:
        SearchAgentFilterParametersDtoCategoriesEnum.MobileHouse,
    [ESubCategory.HOUSEBOAT]:
        SearchAgentFilterParametersDtoCategoriesEnum.Houseboat,
    [ESubCategory.OTHER_OBJECT_FOR_HOUSING]:
        SearchAgentFilterParametersDtoCategoriesEnum.OtherObjectForHousing,
    [ESubCategory.CABIN_AND_LOG_CABIN]:
        SearchAgentFilterParametersDtoCategoriesEnum.CabinAndLogCabin,
    [ESubCategory.COTTAGE_AND_RECREATION_HOUSE]:
        SearchAgentFilterParametersDtoCategoriesEnum.CottageAndRecreationHouse,
    [ESubCategory.GARDEN_HUT]:
        SearchAgentFilterParametersDtoCategoriesEnum.GardenHut,
    [ESubCategory.OTHER_RECREATIONAL_OBJECT]:
        SearchAgentFilterParametersDtoCategoriesEnum.OtherRecreationalObject,
    [ESubCategory.LAND_FOR_FAMILY_HOUSE]:
        SearchAgentFilterParametersDtoCategoriesEnum.LandForFamilyHouse,
    [ESubCategory.RECREATIONAL_LAND]:
        SearchAgentFilterParametersDtoCategoriesEnum.RecreationalLand,
    [ESubCategory.LAND_FOR_HOUSING_CONSTRUCTION]:
        SearchAgentFilterParametersDtoCategoriesEnum.LandForHousingConstruction,
    [ESubCategory.LAND_FOR_CIVIC_AMENITIES]:
        SearchAgentFilterParametersDtoCategoriesEnum.LandForCivicAmenities,
    [ESubCategory.COMMERCIAL_ZONE]:
        SearchAgentFilterParametersDtoCategoriesEnum.CommercialZone,
    [ESubCategory.INDUSTRIAL_ZONE]:
        SearchAgentFilterParametersDtoCategoriesEnum.IndustrialZone,
    [ESubCategory.MIXED_ZONE]:
        SearchAgentFilterParametersDtoCategoriesEnum.MixedZone,
    [ESubCategory.GARDEN]:
        SearchAgentFilterParametersDtoCategoriesEnum.Garden,
    [ESubCategory.ORCHARD]:
        SearchAgentFilterParametersDtoCategoriesEnum.Orchard,
    [ESubCategory.VINEYARD_AND_HOP_GARDEN]:
        SearchAgentFilterParametersDtoCategoriesEnum.VineyardAndHopGarden,
    [ESubCategory.MEADOW_AND_PASTURE]:
        SearchAgentFilterParametersDtoCategoriesEnum.MeadowAndPasture,
    [ESubCategory.ARABLE_LAND]:
        SearchAgentFilterParametersDtoCategoriesEnum.ArableLand,
    [ESubCategory.FOREST_LAND]:
        SearchAgentFilterParametersDtoCategoriesEnum.ForestLand,
    [ESubCategory.POND_AND_LAKE]:
        SearchAgentFilterParametersDtoCategoriesEnum.PondAndLake,
    [ESubCategory.LAND_FOR_ADVERTISING]:
        SearchAgentFilterParametersDtoCategoriesEnum.LandForAdvertising,
    [ESubCategory.OTHER_TYPE_OF_LAND]:
        SearchAgentFilterParametersDtoCategoriesEnum.OtherTypeOfLand,
    [ESubCategory.OFFICES]:
        SearchAgentFilterParametersDtoCategoriesEnum.Offices,
    [ESubCategory.BUSINESS_SPACES]:
        SearchAgentFilterParametersDtoCategoriesEnum.BusinessSpaces,
    [ESubCategory.RESTAURANT_SPACES]:
        SearchAgentFilterParametersDtoCategoriesEnum.RestaurantSpaces,
    [ESubCategory.STORAGE_AREAS]:
        SearchAgentFilterParametersDtoCategoriesEnum.StorageAreas,
    [ESubCategory.SPACE_FOR_PRODUCTION]:
        SearchAgentFilterParametersDtoCategoriesEnum.SpaceForProduction,
    [ESubCategory.REPAIR_AREA]:
        SearchAgentFilterParametersDtoCategoriesEnum.RepairArea,
    [ESubCategory.SPORTS_FACILITIES]:
        SearchAgentFilterParametersDtoCategoriesEnum.SportsFacilities,
    [ESubCategory.SPACE_FOR_ADVERTISEMENT]:
        SearchAgentFilterParametersDtoCategoriesEnum.SpaceForAdvertisement,
    [ESubCategory.OTHER_TYPE_OF_SPACE]:
        SearchAgentFilterParametersDtoCategoriesEnum.OtherTypeOfSpace,
    [ESubCategory.APARTMENT_HOUSE]:
        SearchAgentFilterParametersDtoCategoriesEnum.ApartmentHouse,
    [ESubCategory.RENTAL_HOUSE]:
        SearchAgentFilterParametersDtoCategoriesEnum.RentalHouse,
    [ESubCategory.OFFICE_BUILDING]:
        SearchAgentFilterParametersDtoCategoriesEnum.OfficeBuilding,
    [ESubCategory.COMMERCIAL_OBJECT]:
        SearchAgentFilterParametersDtoCategoriesEnum.CommercialObject,
    [ESubCategory.RESTAURANT]:
        SearchAgentFilterParametersDtoCategoriesEnum.Restaurant,
    [ESubCategory.POLYFUNCTIONAL_BUILDING]:
        SearchAgentFilterParametersDtoCategoriesEnum.PolyfunctionalBuilding,
    [ESubCategory.WAREHOUSE]:
        SearchAgentFilterParametersDtoCategoriesEnum.Warehouse,
    [ESubCategory.MANUFACTURING_FACILITY]:
        SearchAgentFilterParametersDtoCategoriesEnum.ManufacturingFacility,
    [ESubCategory.REPAIR_FACILITY]:
        SearchAgentFilterParametersDtoCategoriesEnum.RepairFacility,
    [ESubCategory.OBJECT_FOR_BREEDING_ANIMALS]:
        SearchAgentFilterParametersDtoCategoriesEnum.ObjectForBreedingAnimals,
    [ESubCategory.HOTEL_AND_PENSION]:
        SearchAgentFilterParametersDtoCategoriesEnum.HotelAndPension,
    [ESubCategory.SPA]:
        SearchAgentFilterParametersDtoCategoriesEnum.Spa,
    [ESubCategory.HISTORICAL_OBJECT]:
        SearchAgentFilterParametersDtoCategoriesEnum.HistoricalObject,
    [ESubCategory.COMMERCIAL_FACILITY]:
        SearchAgentFilterParametersDtoCategoriesEnum.CommercialFacility,
    [ESubCategory.SPORT_OBJECT]:
        SearchAgentFilterParametersDtoCategoriesEnum.SportObject,
    [ESubCategory.SMALL_POWER_STATION]:
        SearchAgentFilterParametersDtoCategoriesEnum.SmallPowerStation,
    [ESubCategory.GAS_STATION]:
        SearchAgentFilterParametersDtoCategoriesEnum.GasStation,
    [ESubCategory.MOBILE_CELLS_AND_STANDS]:
        SearchAgentFilterParametersDtoCategoriesEnum.MobileCellsAndStands,
    [ESubCategory.OTHER_TYPE_OF_OBJECT]:
        SearchAgentFilterParametersDtoCategoriesEnum.OtherTypeOfObject,
    [ESubCategory.DETACHED_GARAGE]:
        SearchAgentFilterParametersDtoCategoriesEnum.DetachedGarage,
    [ESubCategory.CONSOLIDATED_GROUND]:
        SearchAgentFilterParametersDtoCategoriesEnum.ConsolidatedGround,
    [ESubCategory.AGRICULTURAL_OBJECT]:
        SearchAgentFilterParametersDtoCategoriesEnum.AgriculturalObject,
    [ESubCategory.OTHER_LAND_TO_BUILDING]:
        SearchAgentFilterParametersDtoCategoriesEnum.OtherLandToBuilding,
    [ESubCategory.OTHER_AGRICULTURAL_LAND]:
        SearchAgentFilterParametersDtoCategoriesEnum.OtherAgriculturalLand,
    [ESubCategory.SECONDARY_STUDIO_APARTMENT]:
        SearchAgentFilterParametersDtoCategoriesEnum.SecondaryStudioApartment,
    [ESubCategory.SECONDARY_THREE_ROOM_APARTMENT]:
        SearchAgentFilterParametersDtoCategoriesEnum.SecondaryThreeRoomApartment,
    [ESubCategory.SECONDARY_LAND_FOR_FAMILY_HOUSES]:
        SearchAgentFilterParametersDtoCategoriesEnum.SecondaryLandForFamilyHouses,
    [ESubCategory.SECONDARY_RECREATIONAL_LAND]:
        SearchAgentFilterParametersDtoCategoriesEnum.SecondaryRecreationalLand,
    [ESubCategory.SECONDARY_AGRICULTURAL_AND_FOREST_LAND]:
        SearchAgentFilterParametersDtoCategoriesEnum.SecondaryAgriculturalAndForestLand,
    [ESubCategory.SECONDARY_COMMERCIAL_PLOTS]:
        SearchAgentFilterParametersDtoCategoriesEnum.SecondaryCommercialPlots,
    [ESubCategory.SECONDARY_GARDEN]:
        SearchAgentFilterParametersDtoCategoriesEnum.SecondaryGarden,
    [ESubCategory.SECONDARY_GARAGE_AND_PARKING]:
        SearchAgentFilterParametersDtoCategoriesEnum.SecondaryGarageAndParking,
    [ESubCategory.SECONDARY_A_RESTAURANT]:
        SearchAgentFilterParametersDtoCategoriesEnum.SecondaryARestaurant,
    [ESubCategory.SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS]:
        SearchAgentFilterParametersDtoCategoriesEnum.SecondaryOrchardsVineyardsHopFields,
    [ESubCategory.SECONDARY_OFFICES_AND_ADMINISTRATION]:
        SearchAgentFilterParametersDtoCategoriesEnum.SecondaryOfficesAndAdministration,
    [ESubCategory.SECONDARY_PRODUCTION]:
        SearchAgentFilterParametersDtoCategoriesEnum.SecondaryProduction,
    [ESubCategory.SECONDARY_WAREHOUSES]:
        SearchAgentFilterParametersDtoCategoriesEnum.SecondaryWarehouses,
    [ESubCategory.SECONDARY_HOTEL_GUESTHOUSE]:
        SearchAgentFilterParametersDtoCategoriesEnum.SecondaryHotelGuesthouse,
    [ESubCategory.SECONDARY_THE_SHOP]:
        SearchAgentFilterParametersDtoCategoriesEnum.SecondaryTheShop,
    [ESubCategory.SECONDARY_ANOTHER_SPACE_AND_OBJECT]:
        SearchAgentFilterParametersDtoCategoriesEnum.SecondaryAnotherSpaceAndObject,
};
