import React, { FC } from 'react';
import { Formik, FormikProps } from 'formik';
import { IOption } from 'modules/theme/components/fields/radioBox/interfaces/IOption';
import { SearchAgentForm } from 'components/searchAgent/components/saveSearchModal/partials/content/form/Form';
import { getSaveSearchValidationSchema } from 'components/searchAgent/helpers/form/getSaveSearchValidationSchema';
import { ESearchAgentFrequencyEnum } from 'components/searchAgent/enums/ESearchAgentFrequencyEnum';
import { ISearchAgentData } from 'components/searchAgent/interfaces/form/data/ISearchAgentData';

interface IContent {
    onClose: () => void;
    email: string;
    searchName?: string;
    onSend: (data: ISearchAgentData) => Promise<boolean>;
    isLoading: boolean
}

export const Content: FC<IContent> = ({ onClose, email, onSend, isLoading, searchName }) => {
    const options: IOption[] = [
        {
            value: ESearchAgentFrequencyEnum.IMMEDIATELY,
            name: 'Poslať hneď',
        },
        {
            value: ESearchAgentFrequencyEnum.EVERY_DAY,
            name: 'Raz za 24 hodín',
        },
        {
            value: ESearchAgentFrequencyEnum.EVERY_WEEK,
            name: 'Raz za týzdeň',
        },
        {
            value: ESearchAgentFrequencyEnum.DISABLED,
            name: 'Neposielať',
        }
    ];

    const initFormValues: ISearchAgentData = {
        email: email,
        searchName: searchName || '',
        frequency: ESearchAgentFrequencyEnum.EVERY_DAY,
        agreeToTerms: false
    };

    return (
        <Formik
            validateOnChange
            validateOnMount
            validationSchema={getSaveSearchValidationSchema()}
            initialValues={initFormValues}
            onSubmit={(values: ISearchAgentData, { resetForm }) => {
                onSend(values).then((isSentSuccessfully) => {
                    if (isSentSuccessfully) {
                        resetForm({ values: initFormValues });
                        onClose();
                    }
                });
            }}
        >
            {(formikProps: FormikProps<ISearchAgentData>) => (
                <SearchAgentForm
                    {...formikProps}
                    disabled={isLoading}
                    options={options}
                />
            )}
        </Formik>
    );
};
