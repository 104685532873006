import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { FormikProps, Form } from 'formik';
import { Text } from 'modules/theme/components/text/Text';
import { Button } from 'modules/theme/components/button/Button';
import { Input } from 'modules/theme/components/fields/input/Input';
import { Desktop, Mobile } from 'modules/theme/components/responsive';
import { Select } from 'modules/theme/components/fields/select/Select';
import { IOption } from 'modules/theme/components/fields/radioBox/interfaces/IOption';
import { CheckboxWithLabel } from 'modules/theme/components/fields/checkbox/CheckboxWithLabel';
import { ISearchAgentData } from 'components/searchAgent/interfaces/form/data/ISearchAgentData';
import { InputsMergedWrapper } from 'modules/theme/components/fields/input/partials/InputsMergedWrapper';
import { GdprDisclaimer } from 'components/searchAgent/components/saveSearchModal/partials/content/gdprDisclaimer/GdprDisclaimer';

interface ISearchAgentForm extends FormikProps<ISearchAgentData> {
    disabled: boolean;
    options: IOption[];
}

export const SearchAgentForm: FC<ISearchAgentForm> = ({
    errors,
    values,
    touched,
    disabled,
    options,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    isValid
}) => {
    return (
        <Form noValidate autoComplete='off' onSubmit={handleSubmit}>
            <Grid container direction='column' rowGap={2}>
                <Grid item>
                    <Text variant='h4' semibold>Uložte si hľadanie</Text>
                </Grid>
                <Grid item>
                    <Desktop>
                        <Text variant='body2'>
                            Ak pribudne do ponuky nová nehnuteľnosť, <br/>dáme vám o nej vedieť.
                        </Text>
                    </Desktop>
                    <Mobile>
                        <Text variant='body2'>
                            Ak pribudne do ponuky nová nehnuteľnosť, dáme vám o nej vedieť.
                        </Text>
                    </Mobile>
                </Grid>
                <Grid item>
                    <InputsMergedWrapper>
                        <Input
                            disabled
                            value={values.email}
                            label='Váš email'
                            labelShrink
                            errorMsg={touched.email && errors.email}
                            onChange={(value) => setFieldValue('email', value)}
                            onBlur={() => setFieldTouched('email')}
                        />
                        <Input
                            disabled={disabled}
                            value={values.searchName}
                            label='Názov hľadania'
                            errorMsg={touched.searchName && errors.searchName}
                            onChange={(value) => setFieldValue('searchName', value)}
                            onBlur={() => setFieldTouched('searchName')}
                        />
                        <Select
                            options={options}
                            disabled={disabled}
                            label='Frekvencia zasielania'
                            selectedValue={values.frequency}
                            errorMsg={touched.frequency && errors.frequency}
                            onChange={(value) => setFieldValue('frequency', value)}
                            onBlur={() => setFieldTouched('frequency')}
                        />
                    </InputsMergedWrapper>
                </Grid>
                <Grid
                    item
                    display='flex'
                    sx={{
                        '.MuiFormControlLabel-root': {
                            alignItems: 'unset'
                        }
                    }}
                >
                    <CheckboxWithLabel
                        checkboxTopAlign
                        onClick={(isChecked) => setFieldValue('agreeToTerms', isChecked)}
                        isChecked={values?.agreeToTerms}
                        errMsg={touched.agreeToTerms && errors.agreeToTerms}
                        label={
                            <GdprDisclaimer />
                        }
                    />
                </Grid>
                <Grid item display='flex' justifyContent='flex-end'>
                    <Button
                        secondary
                        type='submit'
                        disabled={disabled || !isValid}
                        text='Uložiť hľadanie'
                    />
                </Grid>
            </Grid>
        </Form>
    );
};