'use client';

import { useCallback, useState } from 'react';
import useLoading from 'modules/stores/loading/useLoading';
import { EOrder } from 'modules/api/clients/advertisement/listing/enums/EOrder';
import { AdvertisementListing } from 'modules/api/clients/advertisement/listing/AdvertisementsListing';
import { useLocationStore } from 'components/advertisementsFilter/modules/location/store/useLocationStore';
import { useCategoriesStore } from 'components/advertisementsFilter/modules/categories/store/useCategoriesStore';
import { useTransactionStore } from 'components/advertisementsFilter/modules/transaction/store/useTransactionStore';

interface IReturn {
    searchTitle: string;
    getSearchTitle: () => void;
}

export const useSearchAgentTitle = (): IReturn => {
    const [searchTitle, setSearchTitle] = useState('');
    const { start: startLoading, stop: stopLoading } = useLoading();

    const locations = useLocationStore((store) => store.selectedLocations);
    const categories = useCategoriesStore((store) => store.selectedCategories);
    const transaction = useTransactionStore(
        (store) => store.selectedTransaction
    );

    const getSearchTitle = useCallback(
        async (): Promise<void> => {
            setSearchTitle('');
            startLoading();
            let response: {url: string, title: string} = null;

            try {
                response = await new AdvertisementListing().getAdvertisementsListingPageInternalUrl({
                    locations: locations.map((location) => location.id),
                    categories: categories.map((category) => category.name),
                    transaction: transaction.name,
                    order: EOrder.DEFAULT,
                });
                if (response?.title) {
                    setSearchTitle(response.title);
                }
            } catch (e) {
                console.error(e);
            } finally {
                stopLoading();
            }
        },
        [
            locations,
            categories,
            stopLoading,
            transaction,
            startLoading,
        ]
    );

    return {
        searchTitle,
        getSearchTitle
    };
};
