import {
    AdvertisementSearchAgentSaveSearchAgentDto,
    SearchAgentFilterParametersDtoCategoriesEnum,
    SearchAgentFilterParametersDtoTransactionEnum,
} from 'modules/api/generated';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';
import { IExtendedFilter } from 'components/searchAgent/interfaces/filter/IExtendedFilter';
import { ISearchAgentData } from 'components/searchAgent/interfaces/form/data/ISearchAgentData';
import { CategoryEnumRecord } from 'components/searchAgent/constants/record/api/category/CategoryEnumRecord';
import { FrequencyEnumRecord } from 'components/searchAgent/constants/record/api/frequency/FrequencyEnumRecord';
import { TransactionEnumRecord } from 'components/searchAgent/constants/record/api/transaction/TransactionEnumRecord';
import { ICategory } from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/category/ICategory';
import { ITransaction } from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/transaction/ITransaction';

export const getSearchAgentRequestoDto = (
    formData: ISearchAgentData,
    locations: ILocation[],
    categories: ICategory[],
    transaction: ITransaction,
    extendedFilter: IExtendedFilter
): AdvertisementSearchAgentSaveSearchAgentDto => {
    const {
        area: { to: areaTo, from: areaFrom },
        prices: { to: priceTo, from: priceFrom },
        fulltext,
        isOnlyNewBuilding,
        isOnlyWithVideo
    } = extendedFilter;

    const { email, searchName: searchAgentname, frequency } = formData;

    let requestTransaction: SearchAgentFilterParametersDtoTransactionEnum;
    let requestCategories: SearchAgentFilterParametersDtoCategoriesEnum[] = [];

    const locationIds = locations.length
        ? locations.map((location) => location.id)
        : [];

    if (transaction) {
        requestTransaction = TransactionEnumRecord[transaction.name];
    }

    if (categories) {
        requestCategories = categories.map(
            (cat) => CategoryEnumRecord[cat.name]
        );
    }

    return {
        email,
        searchAgentname,
        frequency: FrequencyEnumRecord[frequency],
        filterData: {
            locations: locationIds,
            categories: requestCategories,
            transaction: requestTransaction,
            ...areaTo && { areaTo },
            ...priceTo && { priceTo },
            ...areaFrom && { areaFrom },
            ...fulltext && { fulltext },
            ...priceFrom && { priceFrom },
            ...isOnlyNewBuilding && { onlyNewbuilding: isOnlyNewBuilding },
            ...isOnlyWithVideo && { onlyWithVideo: isOnlyWithVideo },
        },
    };
};
